$font-family-primary: 'Poppins', Verdana, sans-serif;

$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$line-height: 1.2;

$font-size-lorax: 3.4rem;
$font-size-ibis: 3rem;
$font-size-fawn: 2.4rem;
$font-size-elk: 2.2rem;
$font-size-cougar: 1.8rem;
$font-size-bonobo: 1.6rem;
$font-size-boar: 1.5rem;
$font-size-badger: 1.3rem;
$font-size-aardvark: 1rem;

%font-h1 {
  font: $font-weight-bold rfs(#{$font-size-ibis})/#{$line-height} $font-family-primary;
}

%font-h2 {
  font: $font-weight-bold rfs(#{$font-size-lorax})/#{$line-height} $font-family-primary;
}

%font-h3 {
  font: $font-weight-semibold rfs(#{$font-size-fawn})/#{$line-height} $font-family-primary;
}

%font-h4 {
  font: $font-weight-normal rfs(#{$font-size-elk})/#{$line-height} $font-family-primary;
}

%font-h5 {
  font: $font-weight-normal rfs(#{$font-size-cougar})/#{$line-height} $font-family-primary;
  text-transform: uppercase;
}

%font-large-bold {
  font: $font-weight-bold rfs(#{$font-size-cougar})/#{$line-height} $font-family-primary;
}

%font-large-semibold {
  font: $font-weight-semibold rfs(#{$font-size-cougar})/#{$line-height} $font-family-primary;
}

%font-large {
  font: $font-weight-normal rfs(#{$font-size-cougar})/#{$line-height} $font-family-primary;
}

%font-body-bold {
  font: $font-weight-bold rfs(#{$font-size-bonobo})/#{$line-height} $font-family-primary;
}

%font-body {
  font: $font-weight-normal rfs(#{$font-size-bonobo})/#{$line-height} $font-family-primary;
}

%font-body-subside {
  font: $font-weight-normal rfs(#{$font-size-boar})/#{$line-height} $font-family-primary;
}

%font-tooltip {
  font: $font-weight-normal #{$font-size-badger}/#{$line-height} $font-family-primary;
}

%font-subtext {
  font: $font-weight-normal #{$font-size-aardvark}/#{$line-height} $font-family-primary;
}
