@use "/styles/mq";
@use "/styles/layout";
@use "/styles/colors";
@use "/styles/typo";

.card {
    color: colors.$on-neutral-super;
    background-color: colors.$snow;
    box-shadow: 0 0.35rem 1rem colors.$box-shadow;
    width: 18.2rem;
    height: 27.7rem;
    border-radius: .5rem;
    margin-right: 4rem;

    @include mq.media('<=mobile') {
        margin-right: 1.8rem;
        height: 21.5rem;
    }
}
.card:last-child {
    margin-right: 0;
}

.share {
    border-radius: 50%;
    margin-top: -3.2rem;
    margin-left: 0.3rem;
    position: relative;
}

.image {
    border-radius: .5rem .5rem 0 0;
}

.image_crop {
    @include mq.media('<=mobile') {
        height: 13.6rem;
        overflow: hidden;
    }
}

.card_info {
    padding: 0.8rem;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 17.9rem;
    padding-right: 1.1rem;
}

.badge {
    margin-left: -1.2rem;
}

.text_container {
    display: flex;
    flex-direction: column;
    padding-top: 0.6rem;
}

.track_name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @extend %font-body-bold;
}

.artist_name {
    padding-top: 0.4rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: colors.$on-neutral-companion;
    @extend %font-subtext;
}