@use "/styles/mq";

$app-width-max: 1292px;
$app-width-min: 360px;

@mixin app-layout($withPadding: true, $minPadding: false) {
  min-width: $app-width-min;
  max-width: $app-width-max;
  margin-left: auto;
  margin-right: auto;

  padding-left: 2.4rem;
  padding-right: 2.4rem;

  @if $minPadding {
    @include mq.media('<=tablet') {
      padding-left: .8rem;
      padding-right: .8rem;
    }

    @include mq.media('<=mobile') {
      padding-left: .4rem;
      padding-right: .4rem;
    }
  } @else {
    @include mq.media('<=mobile') {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
  }
}
