@use "/styles/mq";
@use "/styles/layout";
@use "/styles/colors";
@use "/styles/typo";

.date {
	display: flex;
	flex-direction: column;
	place-content: flex-start space-between;
	row-gap: .8rem;

	[class*="react-select__single-value"], [class*="react-select__placeholder"] {
		margin-left: 0.5rem;
		color: colors.$primary;
		@extend %font-body;
	}

	[class*="react-select__menu"] {
		@extend %font-body-subside;
		width: max-content;
      	min-width: 100%;
		padding-top: 0;
	}

	[class*="react-select__control"] {
		border: none;
		box-shadow: none;
		justify-content: start;
		cursor: pointer;
	}

	[class*="react-select__value-container"] {
		display: flex;
		justify-content: start;
		padding: 0;
	}

	[class*="react-select__indicator"] {
		padding: 0;
	}

	[class*="react-select__option"] {
		font-size: 0;
		position: sticky;
		top: 0;
		color: colors.$primary;
		background-color: colors.$snow;
		cursor: pointer;
	}

	[class*="react-select__option"]:after {
		content: "Today's Chart";
		@extend %font-body-subside;
	}

	[class*="react-select__option"] ~ [class*="react-select__option"] {
		color: colors.$black-base;
		position: static;
		@extend %font-body-subside;
	}

	[class*="react-select__option"] ~ [class*="react-select__option"]:after {
		display: none;
	}

	[class*="react-select__option--is-selected"] {
		background-color: colors.$black-xx-light;
	}

	@include mq.media('>mobile', '<=tablet') {
		margin-bottom: .6rem;
	}
}

.week_of {
    display: flex;
	flex-direction: row;
    align-items: center;
	@extend %font-body;
}

.week_selected {
	margin-left: 0.5rem;
	color: colors.$primary;
}

.chevron {
	margin-left: 1rem;
	color: colors.$primary;
	cursor: pointer;
}

.upside_down {
	transform: rotate(180deg);
}

.date_range {
	color: colors.$black-x-light;
	@extend %font-subtext;

	@include mq.media('<=mobile') {
		font-size: rfs(#{typo.$font-size-bonobo});
	}
}