@use "/styles/mq";
@use "/styles/layout";
@use "/styles/colors";
@use "/styles/typo";

.title {
    @extend %font-h3;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 4rem;
    position: relative;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    @media(max-width: 1185px) {
        justify-content: start;
        padding-left: 0.8rem;
    }

    @include mq.media('<=mobile') {
        padding-bottom: 1.5rem;
    }
}

.container::-webkit-scrollbar {
    display: none;
}
