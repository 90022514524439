@use "/styles/mq";
@use "/styles/layout";
@use "/styles/colors";
@use "/styles/utils";

.info_header, .chart {
  padding: 0 2.4rem;
}

.info_header {
  margin-bottom: 4rem;

  @include layout.app-layout(true);
  @include mq.media('<=tablet') {
		margin-bottom: 0;
	}
}

.highlight_reel {
  @include layout.app-layout(true);
}

.chart {
  @include layout.app-layout(true, true);
}

.tooltip {
  width: 21rem;
  text-align: center;
}

.loading {
  background: url("../assets/loader.gif") no-repeat top center;
  background-size: 7rem;
  position: sticky;
  opacity: 0.9;
  background-color: colors.$snow;
  height: 100vh;
  width: 100%;
  z-index: 2;
}

.loading_copy {
  @include utils.screen-reader-only;
}