@use "/styles/colors";
@use "/styles/mq";
@use "/styles/typo";
@use "/styles/utils";

.modal_container {
	display: flex;
	flex-direction: column;
	row-gap: 3rem;
}

.modal {
	position: absolute;
	inset: 35% 35% 23%;
	background: colors.$snow;
	overflow: auto;
	border-radius: 0.3rem;
	outline: none;
	padding: 4rem 4rem 1rem 4rem;
	word-wrap: break-word;

	@include mq.media('<=small') {
		inset: 15% 25% 45%;
	}

	@include mq.media('<=tablet') {
		inset: 15% 15% 45%;
	}

	@include mq.media('<=mobile') {
		inset: 15% 15% 40%;
	}
}

.overlay {
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	transition: opacity 500ms ease-in-out;
}

.overlay_after_open{
    opacity: 1;
}

.overlay_before_close{
    opacity: 0;
}

.heading_container {
	display: flex;
	flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.heading {
	color: colors.$black-base;
	@extend %font-body;
}

.form {
	display: flex;
	flex-direction: column;
	row-gap: 3rem;
}

.email {
	background: transparent;
	border: none;
	border-bottom: 0.1rem solid colors.$black-x-light;
}

.email:focus {
	outline: none;
}

.close_icon {
	color: colors.$primary;
}

.errorSuccessMessage {
	@extend %font-tooltip;
}

.errorMessage {
	color: colors.$error;
}

.successMessage {
	color: colors.$success;
}