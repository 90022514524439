@use "/styles/mq";
@use "/styles/layout";
@use "/styles/colors";
@use "/styles/typo";

.info_header {
	display: grid;
	grid-gap: clamp(1.2rem, 5%, 2.4rem) clamp(1.2rem, 3%, 4rem);
	justify-content: flex-start;
	align-items: stretch;
	grid-template-columns: auto 1fr;
	grid-template-rows: auto 1fr 1fr;

	@include mq.media('<=mobile') {
		display: flex;
		flex-direction: column;
	}
}

.image {
  grid-column: 1;
  grid-row: 1 / 4;

	@include mq.media('<=mobile') {
		display: none;
	}
}

.header {
	grid-column: 2;
	grid-row: 1;
	margin-top: 2.6%;
}

.body {
	grid-column: 2;
	grid-row: 2 / -1;

	display: flex;
	flex-direction: column;
	place-content: flex-start space-between;
	margin-bottom: 2.6%;

	@include mq.media('<=mobile') {
		grid-column: 1 / -1;
		grid-row-start: 2;

		flex-direction: column-reverse;
		row-gap: 1.2rem;
	}
}

.title {
	color: colors.$on-neutral-super;
	margin-top: 0;
	margin-bottom: 0.6rem;
	white-space: nowrap;

	@extend %font-h2;

	@include mq.media('<=mobile') {
		margin-bottom: 1.2;
	}
}

.subtitle {
	color: colors.$on-neutral-companion;
	@extend %font-body-subside;

	@include mq.media('<=mobile') {
		margin-bottom: 2rem;
	}
}

.date {
	display: flex;
	flex-direction: column;
	place-content: flex-start space-between;
	row-gap: .8rem;

	@include mq.media('>mobile', '<=tablet') {
		margin-bottom: .6rem;
	}
}

.week_of {
    display: flex;
	flex-direction: row;
    align-items: center;
	@extend %font-body;
}

.week_selected {
	margin-left: 0.5rem;
	color: colors.$primary;
}

.date_range {
	color: colors.$black-x-light;
	@extend %font-subtext;

	@include mq.media('<=mobile') {
		font-size: rfs(#{typo.$font-size-bonobo});
	}
}

.actions {
	display: inline-flex;
	column-gap: 2.5rem;
	@include mq.media('<=tablet') {
		column-gap: 2vw;
	}

	@include mq.media('<=mobile') {
		column-gap: 2rem;
	}
}

.play_button {
	background-color: colors.$primary;
	color: colors.$light;
	border-radius: .4rem;
	padding: 0.85rem 1.5rem;
	text-align: center;
	display: inline-flex;
	align-items: center;
	column-gap: .85rem;
	min-width: fit-content;
	white-space: nowrap;

	@extend %font-large-semibold;
}