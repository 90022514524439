$primary: #A205E6;
$snow: #FFF;
$light: #F5F5F5;
$black-x-light: #999;
$black-base: #333;
$on-neutral-companion: #4E4950;
$on-neutral-super: #1A181B;
$success: #2DBA7A;
$success-light: #DFF7EC;
$black-xx-light: #EBEAEB;
$xx-light: #DDD;
$error: #E46144;
$box-shadow: #00000024;
$badge-highest-climber: #D4C5F8;
$badge-hottest-debut: #77DAFF;
$badge-longest-streak: #F1B0A1;
$badge-first-appearance: #BB8700;
$badge-debut: #00ADEE;
$badge-comeback: #6A492A;
$badge-comeback-fallback: #3E6993;
$badge-border: #DDDDDD;
