@use "/styles/mq";
@use "/styles/layout";
@use "/styles/colors";
@use "/styles/typo";

$numbers-column-min-width: 3.5rem;

.chart {
	width: 100%;
	table-layout: fixed;
	border-collapse: separate;
	border-spacing: 1.4rem 3rem;

	@include mq.media('<=tablet') {
		border-spacing: .8rem 2.4rem;
	}
}

.header_row {
	color: colors.$black-base;
	transform: translateY(.8rem);

	@extend %font-h5;
}

.header_column__rank, .header_column__rank, .header_column__track {
	text-align: left;
}

.header_column__image {
	visibility: hidden;

	@include mq.media('<=tablet') {
		visibility: visible;
	}
}

.header_column__track {
	@include mq.media('<=tablet') {
		visibility: hidden;
	}
}

.header_column__weeks {
	text-align: center;

	@include mq.media('<=tablet') {
		direction: rtl;
		text-align: right;
		white-space: nowrap;
		overflow: visible;
	}
}

.body_row {
	position: relative;
}

.column__rank, .column__image, .column__weeks {
	text-align: center;
}

.column__rank {
	width: $numbers-column-min-width * 2;
}

.column__image {
	width: 6.2rem;

	@include mq.media('<=tablet') {
		width: 4.8rem;
	}
}

.column__weeks {
	width: 12%;

	@include mq.media('<=small') {
		width: 9rem;
	}

	@include mq.media('<=tablet') {
		width: $numbers-column-min-width;
	}
}

.column__track {
	text-align: left;
	height: 6.4rem;

	@include mq.media('<=small') {
		height: 5rem;
	}
}

.column__badges {
	text-align: right;

	@include mq.media('<=mobile') {
		width: 3rem;
	}
}

.rank_number, .rank_change {
	flex-basis: 50%;
}

.rank_number {
	color: colors.$black-base;
	@extend %font-body-bold;
}

.image__square {
	border-radius: 0.4rem;
}

.image__circle {
	border-radius: 50%;
}

.column_link__rank {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.source_name {
	display: flex;
	flex-direction: column;
    justify-content: center;
	height: 100%;
	width: 100%;
	color: colors.$black-base;
	margin-bottom: .4rem;
	@extend %font-large-bold;
}

.track_name {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.artist_name {
	color: colors.$black-x-light;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	@extend %font-body-subside;
}

.container_badges {
	position: relative;
	display: flex;
    flex-direction: row-reverse;
}

.weeks {
	color: colors.$black-base;
	@extend %font-large;

	@include mq.media('<=tablet') {
		color: colors.$black-x-light;
	}
}

// @SAFARI HACK : Workaround for bug where the positioned parent of an absolutely positioned child is ignored
// https://bugs.webkit.org/show_bug.cgi?id=240961
//
// This hack should only apply to Safari 9+.
// We should remove this once the issue is resolved.
@supports (-webkit-hyphens:none) {
	.source_name {
		&::before {
			position: static;
		}
	}
}