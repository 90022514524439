@use "/styles/utils";
@use "/styles/mq";
@use "/styles/colors";
@use "/styles/typo";

.share_button, .success {
	color: colors.$light;
	background-color: colors.$primary;
	border-radius: 50%;
	border: none;
	padding: 0.9rem;
	display: inline-flex;
	transition: all 0.5s ease-out;
}

.share_button:hover, .success:hover {
	cursor: pointer;
}

.success {
	color: colors.$success;
	background-color: colors.$success-light;
}

.text {
	@include utils.screen-reader-only;
}

.tooltip {
	width: 10%;
	text-align: center;
	@extend %font-tooltip;

	@include mq.media('<=mobile') {
		width: 30%;
	}
}
