// Borrowed from bootstrap: https://github.com/twbs/bootstrap/blob/main/scss/mixins/_visually-hidden.scss
// Useful with text that only exists for accessibility reasons.
@mixin screen-reader-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}