@use "/styles/typo";
@use "/styles/colors";

.rank_change {
  display: flex;
  flex-direction: row;
  height: typo.$font-size-badger;
  align-items: center;

  @extend %font-tooltip;
}

.rank, .arrow, .arrowDown, .arrowRight, .new {
  flex-basis: 50%;
  margin-top: .1rem;
}

.arrow {
  color: colors.$success;
}

.arrowDown {
  color: colors.$error;
  transform: rotate(180deg);
}

.arrowRight {
  color: colors.$black-x-light;
  transform: rotate(90deg);
}
