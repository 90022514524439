@use "/styles/colors";
@use "/styles/typo";
@use "/styles/mq";
@use "/styles/utils";

.Button {
	border-radius: .4rem;
	padding: 0.85rem 1.5rem;
	border: none;
	display: inline-flex;
  text-align: center;
  justify-content: center;
	column-gap: .85rem;
	white-space: nowrap;

	@extend %font-large-semibold;

  &:hover {
    cursor: pointer;
  }
}

.Button__default-width {
	width: fit-content;
}

.Button__full-width {
  text-align: center;
  width: 100%;
}

.primary {
	background-color: colors.$primary;
	color: colors.$light;
}

.secondary {
	background-color: colors.$black-xx-light;
	color: colors.$on-neutral-super;
}

.transparent {
	background-color: transparent;
}

.image-only {
	border-radius: 50%;
	padding: .9rem;
}

.allow-image-only {
	@include mq.media('<=tablet') {
		border-radius: 50%;
		padding: .9rem;
	}

	.text {
		// If there's button text, only show "show" it to screen readers on smaller devices
		@include mq.media('<=tablet') {
			@include utils.screen-reader-only;
		}
	}
}

.image {
	line-height: .1rem;
}

.disabled {
  background-color: colors.$xx-light;

  &:hover {
		cursor: default;
	}
}