@use "/styles/mq";
@use "/styles/layout";
@use "/styles/colors";
@use "/styles/typo";

.container, .highlight_badge {
    color: colors.$on-neutral-super;
    background-color: colors.$snow;
    border: .1rem solid colors.$badge-border;
    padding: .5rem .8rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin-left: 1.2rem;
    border-radius: 1.6rem;
    white-space: nowrap; 
	@extend %font-subtext;
}

.container:nth-last-child(1) {
    @media(max-width: 790px) {
        padding: 0.5rem 0.6rem;
        width: 2.5rem;
        margin-left: 1.2rem;

        .name {
            display: none;
        }
    }
    @include mq.media('<=mobile') {
        padding: 0.4rem 0.5rem;
    }
}

.container:nth-last-child(n+2):first-child,
.container:nth-last-child(n+2):first-child ~ div {
    &:nth-last-child(1) {
        @media(max-width: 1000px) {
            padding: 0.5rem 0.6rem;
            width: 2.5rem;
            margin-left: 1.2rem;
    
            .name {
                display: none;
            }
        }
        @include mq.media('<=mobile') {
            display: none;
        }
    }
    &:nth-last-child(2) {
        @media(max-width: 790px) {
            padding: 0.5rem 0.6rem;
            width: 2.5rem;
            margin-left: 1.2rem;
    
            .name {
                display: none;
            }
        }
        @include mq.media('<=mobile') {
            padding: 0.4rem 0.5rem;
        }
    }
}

.container:nth-last-child(n+3):first-child,
.container:nth-last-child(n+3):first-child ~ div {
    &:nth-last-child(1) {
        @media(max-width: 1222px) {
            padding: 0.5rem 0.6rem;
            width: 2.5rem;
            margin-left: 1.2rem;
    
            .name {
                display: none;
            }
        }
        @include mq.media('<=mobile') {
            padding: 0.4rem 0.5rem;
        }
    }
    &:nth-last-child(2) {
        @media(max-width: 1000px) {
            padding: 0.5rem 0.6rem;
            width: 2.5rem;
            margin-left: 1.2rem;
    
            .name {
                display: none;
            }
        }
        @include mq.media('<=mobile') {
            display: none;
        }
    }
}


.container:nth-last-child(3) {
    @media(max-width: 790px) {
        padding: 0.5rem 0.6rem;
        width: 2.5rem;
        margin-left: 1.2rem;

        .name {
            display: none;
        }
    }
    @include mq.media('<=mobile') {
        padding: 0.4rem 0.5rem;
    }
}

.large {
    width: 12.5rem;
}

.small {
    width: 9.5rem;
}

.longest_streak {
    background-color: colors.$badge-longest-streak;
    border: .1rem solid colors.$badge-longest-streak;
}

.hottest_debut {
    background-color: colors.$badge-hottest-debut;
    border: .1rem solid colors.$badge-hottest-debut;
}

.highest_climber {
    background-color: colors.$badge-highest-climber;
    border: .1rem solid colors.$badge-highest-climber;
}

.comeback {
    color: colors.$badge-comeback;
}

.comeback_fallback {
    color: colors.$badge-comeback-fallback;
}

.peak_position {
    color: colors.$success;
}

.first_appearance {
    color: colors.$badge-first-appearance;
}

.hot_steak {
    color: colors.$error;
}

.debut {
    color: colors.$badge-debut;
}

.emoji {
    padding-right: .6rem;
}